<style scoped>
	.template {height: 100%;width: 100%;overflow: hidden;position: relative;}
	.page_box {height: 100%;width: 100%;overflow: hidden;display: flex;flex-direction: column;}
	.header_box{padding:10px 5px 5px 10px;}
	.main_box {flex: 1;overflow: hidden;}
	.main_box_content{height: 100%;overflow-y: auto;padding: 0 10px 0 10px;}
	.footer_box{text-align: right;padding: 10px 30px 10px 10px;}
	/* 弹窗 */
	.el-dialog__wrapper{position: absolute;left: 0;top: 0;display: flex;align-items: center;overflow: hidden;}
	.el-dialog.my_dialog{margin-top: 0;}
	
	.demo-table-expand {font-size: 0;}
	.demo-table-expand label {width: 90px;color: #99a9bf;}
	.demo-table-expand .el-form-item {margin-right: 0;margin-bottom: 0;width: 50%;}
</style>
<template>
	<div class="template">
		<div class="page_box">
			<div class="header_box">
				<el-button type="warning" size="small" @click="addCommunity">添加账号<i class="el-icon-circle-plus-outline"></i></el-button>
				<el-button type="danger" size="small" @click="api_gettableList">刷新<i class="el-icon-refresh-right"></i></el-button>
			</div>
			<div class="main_box" id="main_box">
				<div class="main_box_content" v-loading="tableLoading">
					<el-table :data="tableList" style="width: 100%" :height="tableHeight" border>
						<el-table-column prop="name" label="姓名" width=""></el-table-column>
						<el-table-column prop="account" label="账号" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="propertyRole.name" label="角色" width="" :formatter="formatterCellval"></el-table-column>
						<el-table-column prop="propertyRole.name" label="状态" width="">
							<template slot-scope="scope">
								<span v-if="scope.row.status==1">{{scope.row.status_text}}</span>
								<span style="color: red;" v-else>{{scope.row.status_text}}</span>
							</template>
						</el-table-column>
						<el-table-column prop="c_name" label="负责小区" width="500"></el-table-column>
						<el-table-column fixed="right" label="操作" width="260">
							<template slot-scope="scope">
								<el-row style="padding-right: 10px;">
									<el-button @click="editCommunity(scope.row)" type="text" size="mini">编辑和更换角色</el-button>
									<el-button @click="deleteCommunity(scope.row)" type="danger" size="mini">删除</el-button>
								</el-row>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div class="footer_box">

			</div>
		</div>
		<!-- 添加角色弹窗 -->
		<el-dialog custom-class="my_dialog" width="80%" top="none" title="添加账号" :visible.sync="dialogAdd" destroy-on-close>
			<add v-on:childrenEvent="handlerChildrenEvent" v-on:closeDialog="closeDialogAdd"></add>
		</el-dialog>

		<!-- 添加角色弹窗 -->
		<el-dialog custom-class="my_dialog" width="60%" top="none" title="编辑账号" :visible.sync="dialogEdit" destroy-on-close>
			<edit v-if="dialogEdit" :id="editId" v-on:childrenEvent="handlerChildrenEventByEditCommiunity" v-on:closeDialog="closeDialogEdit"></edit>
		</el-dialog>
	</div>
</template>

<script>
	import add from './add.vue'
	import edit from './edit.vue'
	var _this;
	export default {
		components: {
			add,
			edit
		},
		data() {
			return {
				tableHeight: 0,
				tableLoading: true,
				tableList: [],
				dialogAdd: false, //添加弹窗
				dialogEdit: false, //编辑弹窗
				editId: 0, //选取的id
				maxIndex: 0, //循环处理数据使用的加载索引
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {
			_this.$nextTick(function() {
				_this.tableHeight = document.getElementById("main_box").offsetHeight;
				_this.api_gettableList();
			})
		},
		methods: {
			/* * 获取角色列表 */
			api_gettableList() {
				_this.maxIndex = 0;
				_this.tableLoading = true;
				_this._getApi('/wy/account/getList', {}).then((res) => {
					if (res.code == 1) {
						var baseData = res.data.list;
						_this.renderCommiunityName(baseData);
					} else {

					}
				}).catch((err) => {})
			},

			// _ ** 小区表格渲染
			renderCommiunityName(baseData) {
				if(baseData.length == 0){
					_this.tableLoading = false;
				}
				var params = {};
				if(!baseData[_this.maxIndex].residence_ids){
					baseData[_this.maxIndex].c_name = '--';
					_this.maxIndex += 1;
					if(_this.maxIndex < baseData.length){
						_this.renderCommiunityName(baseData);
					}else{
						_this.tableList = baseData;
						_this.tableLoading = false;
					}
				}else{
					var idss = baseData[_this.maxIndex].residence_ids.split(',');
					params.ids = idss;
					_this._postApi('/wy/residence/getInfo', params).then((res) => {
						if (res.code == 1) {
							var baseData1 = res.data;
							var str = '';
							for (var i = 0; i < baseData1.length; i++) {
								if(i==baseData1.length-1){
									str += baseData1[i].name+' ';
								}else{
									str += baseData1[i].name+'、 ';
								}
							}
							baseData[_this.maxIndex].c_name = str;
							_this.maxIndex += 1;
							if(_this.maxIndex < baseData.length){
								_this.renderCommiunityName(baseData);
							}else{
								_this.tableList = baseData;
								_this.tableLoading = false;
							}
						} else {
					
						}
					}).catch((err) => {});
				}
			},

			// _ ** 表格占位
			formatterCellval(row, column, cellValue, index) {
				// console.log(Boolean(cellValue), cellValue);
				if (!cellValue) {
					return "--";
				} else {
					return cellValue;
				}
			},

			// _ ** 添加角色
			addCommunity() {
				_this.dialogAdd = true;
			},

			// _ ** 编辑角色
			editCommunity(object) {
				_this.editId = object.id;
				_this.dialogEdit = true;
			},

			// _ ** 删除某个角色
			deleteCommunity(object) {
				var h = _this.$createElement;
				_this.$msgbox({
					title: '不可逆操作提示',
					message: h('p', null, [
						h('span', null, '确定要删除 '),
						h('i', {
							style: 'color: teal'
						}, object.name),
						h('span', null, ' 角色吗? ')
					]),
					showCancelButton: true,
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					beforeClose: (action, instance, done) => {
						if (action === 'confirm') {
							instance.confirmButtonLoading = true;
							instance.confirmButtonText = '执行中...';
							_this._postApi('/wy/account/delete', {
								id: object.id
							}).then((res) => {
								if (res.code == 1) {
									done();
									_this.api_gettableList();
									setTimeout(() => {
										instance.confirmButtonLoading = false;
									}, 300);
								} else {
									_this.$message.error(res.msg);
									done();
									instance.confirmButtonLoading = false;
								}
							}).catch((err) => {

							});
						} else {
							done();
						}
					}
				}).then(action => {
					// this.$message({
					// 	type: 'info',
					// 	message: 'action: ' + action
					// });
				});
			},

			// _ ** 处理添加角色子组件事件
			handlerChildrenEvent(e) {
				_this.api_gettableList();
				_this.dialogAdd = false;
			},
			// _ ** 关闭添加弹窗
			closeDialogAdd() {
				_this.dialogAdd = false;
			},
			// _ ** 处理编辑小区子组件事件
			handlerChildrenEventByEditCommiunity(e) {
				_this.api_gettableList();
				_this.dialogEdit = false;
			},
			closeDialogEdit() {
				_this.dialogEdit = false;
			}
		}
	}
</script>
