<style scoped>
	.dialog_page_footer{text-align: right;}
	.community_box{max-height: 12rem;overflow-y: auto;}
</style>

<template>
	<div class="dialog_page">
		<div class="dialog_page_header"></div>
		<div class="dialog_page_body">
			<el-form label-width="80px">
				<el-row :gutter="20">
					<el-col :span="8">
						<el-form-item label="姓名">
							<el-input v-model="formData.name"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="账号">
							<el-input v-model="formData.account"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="20">
					<el-col :span="8">
						<el-form-item label="角色">
							<el-select v-model="formData.role_id" placeholder="请选择角色">
								<el-option v-for="item in roleList" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="状态">
							<el-switch v-model="formData.status" active-text="正常" inactive-text="禁止"></el-switch>
						</el-form-item>
					</el-col>
				</el-row>
				<el-form-item label="管理小区">
					<div class="community_box">
						<el-checkbox-group v-model="formData.residence_ids">
							<el-checkbox v-for="item in communityList" :label="item.id" name="type">
								{{item.name}}
							</el-checkbox>
						</el-checkbox-group>
					</div>
				</el-form-item>
			</el-form>
		</div>
		<div class="dialog_page_footer">
			<el-button @click="clickCancel">取消</el-button>
			<el-button type="success" :loading="btnSaveLoading" @click="api_saveCommit">立即保存</el-button>
		</div>
	</div>
</template>

<script>
	var _this;
	export default {
		props: ['id'],
		data() {
			return {
				btnSaveLoading: false,
				communityList: [], //小区列表
				roleList: [], //角色列表
				formData: {
					name: '',
					account: '',
					password: '',
					role_id: '',
					residence_ids: []
				}
			}
		},
		created: function() {
			_this = this;
			_this.api_getCommiunityList();
			_this.api_getRoleList();
			_this.api_getThisInfo();
		},
		mounted: function() {

		},
		methods: {
			// _ ** 点击取消
			clickCancel() {
				_this.$emit('closeDialog', '');
			},
			// _ ** 获取小区列表
			api_getCommiunityList() {
				_this._getApi('/wy/account/getResidenceListByAdmin', {}).then((res) => {
					if (res.code == 1) {
						_this.communityList = res.data;
					} else {

					}
				}).catch((err) => {});
			},
			// _ ** 获取角色列表
			api_getRoleList() {
				_this._getApi('/wy/role/getList', {}).then((res) => {
					if (res.code == 1) {
						_this.roleList = res.data.list;
					} else {

					}
				}).catch((err) => {})
			},

			// _ ** 获取角色详情
			api_getThisInfo() {
				var params = {};
				params.id = _this.id;
				_this._getApi('/wy/account/getInfo', params).then((res) => {
					if (res.code == 1) {
						var str = res.data.residence_ids;
						var arr = str.split(',');
						arr = arr.map(Number);
						res.data.residence_ids = arr;
						var baseData = res.data;
						var accountStatus = baseData.status;
						if(accountStatus==1){
							baseData.status = true;
						}else{
							baseData.status = false;
						}
						_this.formData = res.data;
					} else {

					}
				}).catch((err) => {})
			},

			// _ ** 添加保存
			api_saveCommit() {
				_this.btnSaveLoading = true;
				var params = _this.formData;
				params.sure_password = params.password;
				if(params.status){
					params.status = 1;
				}else{
					params.status = 99;
				};
				_this._postApi('/wy/account/update', params).then((res) => {
					if (res.code == 1) {
						_this.btnSaveLoading = false;
						_this.$emit('childrenEvent', '');
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			}
		}
	}
</script>
